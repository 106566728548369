import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: "1",
    margin: "20px",
    backgroundColor: "#C4D19B",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
      transform: "translateY(-3px)",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },

  media: {
    height: 0,
    // paddingTop: "56.25%", // 16:9
    paddingTop: "75.00%", // 4:3
  },

  link: {
    textDecoration: "none",
  },

  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0 10px 0",
  },

  name: {
    margin: "0",
    fontFamily: "JosefinSans-Regular",
    fontSize: "30px",
    color: "#714885",
    lineHeight: "1.15",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "#714885 2px underline",
      textUnderlineOffset: "3px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  price: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "30px",
    color: "#714885",
    lineHeight: "1.15",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "#714885 2px underline",
      textUnderlineOffset: "3px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  description: {
    fontFamily: "FiraMono-Regular",
    fontSize: "15px",
    textDecoration: "none",
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));
