import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  subsectionHeader: {
    fontFamily: "FiraMono-Regular",
    fontSize: "30px",
    color: "#595959",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "25px",
  },

  backButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
    [theme.breakpoints.down(400)]: {
      width: "86  px",
      height: "40px",
    },
  },

  payButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
    [theme.breakpoints.down(400)]: {
      width: "120px",
      height: "40px",
    },
  },
}));
