import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  subsectionHeader: {
    fontFamily: "FiraMono-Regular",
    fontSize: "30px",
    color: "#595959",
    textAlign: "center",
  },

  listItem: {
    padding: "10px 0",
  },

  cardMedia: {
    height: "40px",
    width: "40px",
    borderRadius: "2px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  listItemText: {
    fontFamily: "FiraMono-Regular",
  },

  listItemTextSecondary: {
    fontFamily: "FiraMono-Regular",
  },

  price: {
    fontFamily: "FiraMono-Regular",
  },

  totals: {
    fontFamily: "FiraMono-Regular",
    fontWeight: "bold",
  },

  listItemTextTotal: {
    fontFamily: "FiraMono-Regular",
    fontSize: "26px",
    fontWeight: "bold",
  },

  totalCost: {
    fontFamily: "FiraMono-Regular",
    fontWeight: "bold",
    fontSize: "26px",
  },
}));
