import React from "react";
import { Typography, Button } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title} gutterBottom>
        Page Not Found
      </Typography>
      <HelpOutline className={classes.questionIcon} />
      <Typography className={classes.homeText}>Click to go home</Typography>
      <Button
        className={classes.backButton}
        component={Link}
        to="/"
        size="large"
        type="button"
        variant="contained"
        color="primary"
      >
        Go Home
      </Button>
    </div>
  );
};

export default NotFound;
