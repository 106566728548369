import React, { Fragment } from "react";
import useStyles from "./styles";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";

const Review = ({ checkoutToken, shippingData }) => {
  const classes = useStyles();
  const subTotal = checkoutToken.live.subtotal.raw;
  const shippingCurrency = checkoutToken.live.currency.symbol;
  const shippingCost = shippingData.shippingOptions[0].price.raw;
  const shippingCostFormat = shippingCost.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const totalPrice = subTotal + shippingCost;
  const totalPriceFormat = totalPrice.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Fragment>
      <Typography className={classes.subsectionHeader} gutterBottom>
        Order Summary
      </Typography>
      <List disablePadding>
        {checkoutToken.live.line_items.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
                secondary: classes.listItemTextSecondary,
              }}
              primary={product.name}
              secondary={`Quantity: ${product.quantity}`}
            />
            <Typography className={classes.price}>
              {product.line_total.formatted_with_symbol}
            </Typography>
          </ListItem>
        ))}
        <Divider />
        <ListItem style={{ padding: "10px 0" }}>
          <ListItemText
            classes={{
              primary: classes.listItemText,
            }}
            primary="Subtotal:"
          />
          <Typography className={classes.totals}>
            {checkoutToken.live.subtotal.formatted_with_symbol}
          </Typography>
        </ListItem>

        <ListItem style={{ padding: "10px 0" }}>
          <ListItemText
            classes={{
              primary: classes.listItemText,
            }}
            primary="Shipping:"
          />
          <Typography className={classes.totals}>
            {shippingCurrency}
            {shippingCostFormat}
          </Typography>
        </ListItem>

        <ListItem style={{ padding: "10px 0" }}>
          <ListItemText
            classes={{
              primary: classes.listItemTextTotal,
            }}
            primary="Total:"
          />
          <Typography className={classes.totalCost}>
            {shippingCurrency}
            {totalPriceFormat}
          </Typography>
        </ListItem>
      </List>
    </Fragment>
  );
};

export default Review;
