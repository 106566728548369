import React from "react";
import {
  Email,
  Twitter,
  // Facebook,
  Instagram,
  YouTube,
} from "@material-ui/icons";
import { Grid, Container, Box, Link } from "@material-ui/core";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Box className={classes.footer}>
        <Container>
          <Grid container spacing={0}>
            <Grid className={classes.box} item xs={12} sm={6}>
              <Box className={classes.title} borderBottom={1}>
                Contact Me
              </Box>

              <Box className={classes.link}>
                <a
                  className={classes.email}
                  href="mailto: The.dapper.cat83@gmail.com"
                >
                  <Email className={classes.socialIcon} />
                  Email
                </a>
              </Box>

              <Box className={classes.link}>
                <Link
                  href="https://www.instagram.com/the_dapper_cat_/"
                  color="inherit"
                >
                  <Instagram className={classes.socialIcon} />
                  Instagram
                </Link>
              </Box>
            </Grid>

            <Grid className={classes.box} item xs={12} sm={6}>
              <Box className={classes.title} borderBottom={1}>
                Follow Me
              </Box>

              {/* <Box className={classes.link}>
                <Link href="/" color="inherit">
                  <Facebook className={classes.socialIcon} />
                  Facebook
                </Link>
              </Box> */}

              <Box className={classes.link}>
                <Link
                  className={classes.box}
                  href="https://www.youtube.com/channel/UCo8_orQrWYPQ6p-dtmYoGnA"
                  color="inherit"
                >
                  <YouTube className={classes.socialIcon} />
                  Youtube
                </Link>
              </Box>
              <Box className={classes.link}>
                <Link href="https://twitter.com/CatherineRose_" color="inherit">
                  <Twitter className={classes.socialIcon} />
                  Twitter
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.copy} textAlign="center">
            &copy; The Dapper Cat {new Date().getFullYear()}. All rights
            reserved <span className={classes.pipe}>|</span> Privacy
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
