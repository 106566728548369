import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },

  title: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "60px",
    color: "#714885",
    textAlign: "center",
    marginTop: "50px",
    [theme.breakpoints.down(600)]: {
      fontSize: "45px",
    },
  },

  questionIcon: {
    fontSize: "125px",
    color: "#595959",
    marginBottom: "30px",
  },

  homeText: {
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    marginBottom: "40px",
  },

  backButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "64px",
    },
  },
}));
