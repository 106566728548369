import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& p": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    "& img": {
      width: "50%",
      margin: "20px 0 20px 0",
      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
  },

  post: {
    backgroundColor: "#C4D19B",
    borderRadius: "4px",
    maxWidth: "1100px",
    margin: "0 auto",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
  },

  imgContainer: {
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },

  content: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
      padding: "20px",
    },
  },

  thumbnail: {
    width: "100%",
    borderRadius: "4px 4px 0 0",
  },

  name: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "60px",
    color: "#714885",
    margin: "0px 0px 15px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      textAlign: "center",
    },
  },

  center: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  date: {
    color: "#595959",
    fontFamily: "JosefinSans-Regular",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  hr: {
    margin: "30px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      margin: "24px",
    },
  },

  markdown: {
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    marginBottom: "20px",
    "& iframe": {
      display: "block",
      margin: "0 auto",
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },

  buttonHolder: {
    display: "flex",
    // justifyContent: "space-between",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },

  backButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
  },
}));
