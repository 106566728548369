import React from "react";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import postList from "../../../posts.json";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import useStyles from "./styles";

const Post = (props) => {
  const classes = useStyles();
  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const fetchedPost = {};
  let postExists = false;

  postList.forEach((post, i) => {
    if (validId === post.id) {
      fetchedPost.title = post.title ? post.title : "No title found";
      fetchedPost.date = post.date ? post.date : "No date found";
      fetchedPost.author = post.author ? post.author : "No author found";
      fetchedPost.content = post.content ? post.content : "No content found";
      fetchedPost.thumbnail = post.content
        ? post.thumbnail
        : "No thumbnail found";
      postExists = true;
    }
  });

  if (postExists === false) {
    return <Redirect to="/404" />;
  }

  return (
    <div>
      <div className={classes.post}>
        <div className={classes.imgContainer}>
          {fetchedPost.thumbnail && (
            <img
              className={classes.thumbnail}
              src={fetchedPost.thumbnail}
              alt=""
            />
          )}
        </div>
        <div className={classes.root}>
          <div className={classes.content}>
            <h2 className={classes.name}>{fetchedPost.title}</h2>
            <div className={classes.center}>
              <small className={classes.date}>
                Published on {fetchedPost.date} by {fetchedPost.author}
              </small>
            </div>
            <hr className={classes.hr} />
            <Markdown
              className={classes.markdown}
              children={fetchedPost.content}
              rehypePlugins={[rehypeRaw]}
            />
            <hr className={classes.hr} />
            <div className={classes.buttonHolder}>
              <Button
                className={classes.backButton}
                size="large"
                component={Link}
                to="/blog"
                variant="outlined"
                type="button"
              >
                Back to Blog
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
