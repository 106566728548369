import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: "center",
  },

  noItemsText: {
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    marginBottom: "40px",
  },

  shopButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "64px",
    },
  },

  cardDetails: {
    display: "flex",
    marginTop: "80px",
    paddingTop: "50px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #714885",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "50px",
      paddingTop: "30px",
    },
  },

  subTotal: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "40px",
    color: "#714885",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      textAlign: "center",
    },
    [theme.breakpoints.down(410)]: {
      fontSize: "30px",
      textAlign: "center",
    },
  },

  buttonHolder: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "25px",
      marginBottom: "50px",
    },
  },

  emptyButton: {
    [theme.breakpoints.up("md")]: {
      marginRight: "20px",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",

    [theme.breakpoints.down(400)]: {
      minWidth: "0",
      fontSize: "12px",
      padding: "12px 10px 8px 10px",
    },
  },

  checkoutButton: {
    minWidth: "150px",
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down(400)]: {
      minWidth: "0",
      width: "110px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
  },

  loadingDiv: {
    width: "100%",
    height: "100%",
  },

  loading: {
    position: "fixed",
    left: "50%",
    top: "35%",
    color: "#714885",
  },

  title: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "60px",
    color: "#714885",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      fontSize: "45px",
      marginTop: "-30px",
      marginBottom: "-30px",
    },
  },
}));
