import React, { Fragment, useState, useEffect } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import FormInput from "../../Checkout/AddressForm/CustomTextField/CustomTextField";
import { useForm, FormProvider } from "react-hook-form";
import { commerce } from "../../../../lib/commerce";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const AddressForm = ({ checkoutToken, next }) => {
  const [shippingCountries, setShippingCountries] = useState([]);
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
  const [shippingSubdivision, setShippingSubdivision] = useState("");
  const [shippingOptions, setShippingOptions] = useState([]);
  const [shippingOption, setShippingOption] = useState("");

  const classes = useStyles();

  const countries = Object.entries(shippingCountries).map(
    ([code, countryName]) => ({ id: code, label: countryName })
  );
  // convert from object to 2d array. Map over it to turn it into a normal array. returns array with object with ID and label
  const subdivisions = Object.entries(shippingSubdivisions).map(
    ([code, countryName]) => ({ id: code, label: countryName })
  );

  const options = shippingOptions.map((shipOp) => ({
    id: shipOp.id,
    label: `${shipOp.description} - (${shipOp.price.formatted_with_symbol})`,
  }));

  const fetchShippingCountries = async (checkoutTokenId) => {
    const { countries } = await commerce.services.localeListShippingCountries(
      checkoutTokenId
    );
    setShippingCountries(countries);
    setShippingCountry(Object.keys(countries)[0]);
  };

  const fetchSubdivisions = async (countryCode) => {
    const { subdivisions } = await commerce.services.localeListSubdivisions(
      countryCode
    );
    setShippingSubdivisions(subdivisions);
    setShippingSubdivision(Object.keys(subdivisions)[0]);
  };

  const fetchShippingOptions = async (
    checkoutTokenId,
    country,
    region = null
  ) => {
    const options = await commerce.checkout.getShippingOptions(
      checkoutTokenId,
      { country, region }
    );

    setShippingOptions(options);
    setShippingOption(options[0].id);
  };

  useEffect(() => {
    fetchShippingCountries(checkoutToken.id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shippingCountry) fetchSubdivisions(shippingCountry);
    setShippingSubdivision("");
    setShippingOption("");
    // Fix state warning
  }, [shippingCountry]);

  useEffect(() => {
    if (shippingSubdivision)
      fetchShippingOptions(
        checkoutToken.id,
        shippingCountry,
        shippingSubdivision
      );
    // eslint-disable-next-line
  }, [shippingSubdivision]);

  const methods = useForm();

  return (
    <Fragment>
      <Typography className={classes.subsectionHeader} gutterBottom>
        Shipping Address
      </Typography>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            next({
              ...data,
              shippingCountry,
              shippingSubdivision,
              shippingOption,
              shippingOptions,
            })
          )}
        >
          <Grid container spacing={3}>
            <FormInput name="firstName" label="First Name" />
            <FormInput name="lastName" label="Last Name" />
            <FormInput name="address1" label="Address" />
            <FormInput name="email" label="Email" />
            <FormInput name="city" label="City" />
            <FormInput name="zip" label="Post Code" />
            <Grid item xs={12} sm={6} className={classes.spacer}>
              <InputLabel className={classes.text}>
                Shipping Country:
              </InputLabel>
              <Select
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                value={shippingCountry}
                fullWidth
                onChange={(e) => setShippingCountry(e.target.value)}
              >
                {countries.map((country) => (
                  <MenuItem
                    className={classes.menuItemText}
                    key={country.id}
                    value={country.id}
                  >
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.spacer}>
              <InputLabel className={classes.text}>
                Shipping SubDivision:
              </InputLabel>
              <Select
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                value={shippingSubdivision}
                fullWidth
                onChange={(e) => setShippingSubdivision(e.target.value)}
              >
                {subdivisions.map((SubDivision) => (
                  <MenuItem
                    className={classes.menuItemText}
                    key={SubDivision.id}
                    value={SubDivision.id}
                  >
                    {SubDivision.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.text}>Shipping Option:</InputLabel>
              <Select
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                value={shippingOption}
                fullWidth
                onChange={(e) => setShippingOption(e.target.value)}
              >
                {options.map((option) => (
                  <MenuItem
                    className={classes.menuItemText}
                    key={option.id}
                    value={option.id}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <br />
          <div
            className={classes.buttonHolder}
            style={{ display: "flex", justifyContent: "space-between " }}
          >
            <Button
              className={classes.backButton}
              size="large"
              component={Link}
              to="/cart"
              variant="outlined"
              type="button"
            >
              Back to Cart
            </Button>

            <Button
              className={classes.nextButton}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </div>
        </form>
      </FormProvider>
    </Fragment>
  );
};

export default AddressForm;
