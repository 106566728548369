import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    backgroundColor: "#C4D19B",
  },

  media: {
    height: 260,
  },

  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },

  spacer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  name: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "30px",
    color: "#714885",
    [theme.breakpoints.down(450)]: {
      fontSize: "20px",
    },
  },

  price: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "30px",
    color: "#714885",
    [theme.breakpoints.down(450)]: {
      fontSize: "20px",
    },
  },

  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "4px",
  },

  remove: {
    color: "rgba(0,0,0,0.5)",
  },
  add: {
    color: "rgba(0,0,0,0.5)",
  },

  removeButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down(400)]: {
      minWidth: "0",
      width: "80px",
      height: "36px",
      fontSize: "12px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },

  quantity: {
    fontFamily: "FiraMono-Regular",
    fontSize: "20px",
    margin: "3px 4px",
  },
}));
