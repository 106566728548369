import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,

  root: {
    flexGrow: 1,
  },

  content: {
    flexGrow: 1,
    backgroundColor: "#F5FFD6",
  },

  title: {
    fontFamily: "AmaticSC-Bold",
    fontSize: "200px",
    color: "#595959",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "130px",
    },
  },

  description: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "38px",
    marginBottom: "25px",
    color: "#714885",
    lineHeight: "1.15",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      marginBottom: "10px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "30px",
      marginBottom: "10px",
    },
  },

  container: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
}));
