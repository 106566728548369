import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Divider,
  Button,
  CssBaseline,
} from "@material-ui/core";
import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import StepConnector from "@material-ui/core/StepConnector";
import PaymentForm from "./Review/PaymentForm/PaymentForm";
import { withStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import AddressForm from "./AddressForm/AddressForm";
import { commerce } from "../../../lib/commerce";
import useStyles from "./styles";

const steps = ["Shipping address", "Payment details"];

const Checkout = ({
  cart,
  order,
  onCaptureCheckout,
  error,
  refreshCart,
  resetError,
}) => {
  const [checkoutToken, setCheckoutToken] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [shippingData, setShippingData] = useState({});
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const generateToken = async () => {
      try {
        const token = await commerce.checkout.generateToken(cart.id, {
          type: "cart",
        });
        setCheckoutToken(token);
      } catch (error) {
        history.push("/");
      }
    };
    generateToken();
    // eslint-disable-next-line
  }, [cart]);

  const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const next = (data) => {
    setShippingData(data);
    nextStep();
  };

  let Confirmation = () =>
    order.customer ? (
      <Fragment>
        <div className={classes.container}>
          <CheckCircleOutline className={classes.checkIcon} />
          <Typography className={classes.thankText}>
            Thankyou for your purchase, {order.customer.firstname}!
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.thankText}>
            Order ref: {order.customer_reference}
          </Typography>
          <Button
            className={classes.shopButton}
            onClick={refreshCart}
            component={Link}
            to="/"
            size="large"
            type="button"
            variant="contained"
            color="primary"
          >
            Continue Shopping
          </Button>
        </div>
      </Fragment>
    ) : (
      <div className={classes.spinner}>
        <CircularProgress size={80} className={classes.loading} />
      </div>
    );

  if (error) {
    Confirmation = () => (
      <Fragment>
        <div className={classes.container}>
          <ErrorOutline className={classes.errorIcon} />
          <Typography className={classes.thankText}>Error: {error}</Typography>
          <Button
            className={classes.shopButton}
            onClick={resetError}
            component={Link}
            to="/cart"
            size="large"
            type="button"
            variant="contained"
            color="primary"
          >
            Back to Cart
          </Button>
        </div>
      </Fragment>
    );
  }

  const Form = () =>
    activeStep === 0 ? (
      <AddressForm next={next} checkoutToken={checkoutToken} />
    ) : (
      <PaymentForm
        shippingData={shippingData}
        checkoutToken={checkoutToken}
        backStep={backStep}
        onCaptureCheckout={onCaptureCheckout}
        nextStep={nextStep}
      />
    );

  const QontoConnector = withStyles({
    line: {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
  })(StepConnector);
  //Style stepper connector line

  return (
    <Fragment>
      <CssBaseline />
      <div />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Checkout</Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<QontoConnector />}
          >
            {steps.map((step) => (
              <Step key={step}>
                <StepLabel
                  classes={{
                    root: classes.labelRoot,
                    label: classes.label,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      text: classes.text,
                      completed: classes.completed,
                      active: classes.active,
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Confirmation />
          ) : (
            checkoutToken && <Form />
          )}
        </Paper>
      </main>
    </Fragment>
  );
};

export default Checkout;
