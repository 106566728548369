import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#714885",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },

    "& .MuiFormLabel-root": {
      color: "black",
      fontFamily: "FiraMono-Regular",
      fontSize: "17px",
    },
  },
}));
