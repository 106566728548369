import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const Product = ({ product }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        component={Link}
        to={"/" + product.id}
        className={classes.media}
        image={product.image.url}
        title={product.name}
      />
      <CardContent className={classes.link}>
        <div className={classes.cardContent}>
          <Typography
            className={classes.name}
            component={Link}
            to={"/" + product.id}
          >
            {product.name}
          </Typography>
          <Typography
            className={classes.price}
            component={Link}
            to={"/" + product.id}
          >
            {product.price.formatted_with_symbol}
          </Typography>
        </div>
        <Typography
          dangerouslySetInnerHTML={{ __html: product.description }}
          className={classes.description}
          component={Link}
          to={"/" + product.id}
        />
      </CardContent>
    </Card>
  );
};

export default Product;
