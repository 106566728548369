import React, { Fragment } from "react";
import { Typography, Button, Divider } from "@material-ui/core";
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { HttpsOutlined } from "@material-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import useStyles from "./styles";
import Review from "../Review";
import "./styles.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css2?family=Fira+Mono",
    },
  ],
};

const CARD_OPTIONS = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#714885",
      color: "black",
      fontFamily: "'Fira Mono', monospace",
      fontSize: "14px",
      "::placeholder": {
        color: "grey",
        fontFamily: "'Fira Mono', monospace",
      },
    },
    invalid: {
      iconColor: "#9C574C",
      color: "#9C574C",
    },
  },
};

const CARD_OPTIONS_EXPIRY_CVC = {
  style: {
    base: {
      color: "black",
      fontFamily: "'Fira Mono', monospace",
      fontSize: "14px",
      "::placeholder": {
        color: "grey",
        fontFamily: "'Fira Mono', monospace",
      },
    },
    invalid: {
      iconColor: "#9C574C",
      color: "#9C574C",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardNumberElement
      options={CARD_OPTIONS}
      onChange={onChange}
      className="cardStyle"
    />

    <div className="container">
      <CardExpiryElement
        options={CARD_OPTIONS_EXPIRY_CVC}
        onChange={onChange}
        className="cardStyle-half"
      />
      <CardCvcElement
        options={CARD_OPTIONS_EXPIRY_CVC}
        onChange={onChange}
        className="cardStyle-half"
      />
    </div>
  </div>
);

const PaymentForm = ({
  checkoutToken,
  backStep,
  shippingData,
  onCaptureCheckout,
  nextStep,
}) => {
  const subTotal = checkoutToken.live.subtotal.raw;
  const shippingCurrency = checkoutToken.live.currency.symbol;
  const shippingCost = shippingData.shippingOptions[0].price.raw;
  const totalPrice = subTotal + shippingCost;
  const totalPriceFormat = totalPrice.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const classes = useStyles();

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    if (!stripe || !elements || !totalPrice) return;

    const cardElement = elements.getElement(
      CardNumberElement,
      CardExpiryElement,
      CardCvcElement
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log(error);
    } else {
      const orderData = {
        line_items: checkoutToken.live.line_items,
        customer: {
          firstname: shippingData.firstName,
          lastname: shippingData.lastName,
          email: shippingData.email,
        },
        shipping: {
          name: "Primary",
          street: shippingData.address1,
          town_city: shippingData.city,
          county_state: shippingData.shippingSubdivision,
          postal_zip_code: shippingData.zip,
          country: shippingData.shippingCountry,
        },
        fulfillment: {
          shipping_method: shippingData.shippingOption,
        },
        payment: {
          gateway: "stripe",
          stripe: { payment_method_id: paymentMethod.id },
          pay_what_you_want: { totalPrice },
        },
      };
      onCaptureCheckout(checkoutToken.id, orderData);
      nextStep();
    }
  };

  return (
    <Fragment>
      <Review checkoutToken={checkoutToken} shippingData={shippingData} />
      <Divider />
      <Typography className={classes.subsectionHeader} gutterBottom>
        Payment Method
      </Typography>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
              <CardField />
              <br />
              <br />
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className={classes.root}
              >
                <Button
                  className={classes.backButton}
                  size="large"
                  variant="outlined"
                  onClick={backStep}
                >
                  Back
                </Button>
                <Button
                  className={classes.payButton}
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={<HttpsOutlined />}
                  disabled={!stripe}
                >
                  Pay {shippingCurrency}
                  {totalPriceFormat}
                </Button>
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    </Fragment>
  );
};

export default PaymentForm;
