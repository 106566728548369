import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#C4D19B",
    color: "#595959",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  title: {
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    fontFamily: "AmaticSC-Bold",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },

  image: {
    opacity: "0.85",
    marginRight: "20px",
    height: "45px",
    "@media (max-width: 300px)": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      marginRight: "10px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "35px",
      marginRight: "17px",
    },
  },

  grow: {
    flexGrow: 1,
  },

  blogButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "7px",
    marginRight: "24px",
    color: "#595959",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },

  customBadge: {
    backgroundColor: "#714885",
    color: "white",
  },
}));
