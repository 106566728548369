import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import Product from "./Product/Product";
import useStyles from "./styles";

const Products = ({ products, onAddToCart }) => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div />
      <Container maxWidth="lg">
        <Typography
          className={classes.title}
          variant="h1"
          align="center"
          color="textPrimary"
        >
          The Dapper Cat
        </Typography>
        <Typography className={classes.description} align="center" paragraph>
          Fine Handmade Goods <br />
          Made in Torquay, UK
        </Typography>
      </Container>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container justifyContent="center">
          {products.map((product) => (
            <Grid
              item
              key={product.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{ display: "flex" }}
            >
              <Product product={product} onAddToCart={onAddToCart} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
};

export default Products;
