import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },

  checkIcon: {
    fontSize: "125px",
    color: "#778548",
    marginBottom: "30px",
  },

  thankText: {
    fontFamily: "FiraMono-Regular",
    fontSize: "20px",
    marginBottom: "40px",
  },

  divider: {
    margin: "20px 0",
  },

  shopButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
  },

  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#714885",
  },

  loading: {
    color: "#714885",
  },

  errorIcon: {
    fontSize: "125px",
    color: "#9C574C",
    marginBottom: "30px",
  },

  appBar: {
    position: "relative",
  },

  layout: {
    width: "auto",
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  paper: {
    backgroundColor: "#C4D19B",
    marginTop: "64px",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: "30px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "30px",
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor: "#C4D19B",
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  title: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "60px",
    color: "#714885",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "50px",
    },
  },

  labelRoot: {
    [theme.breakpoints.down("xs")]: {
      width: "100px",
    },
  },

  label: {
    fontFamily: "FiraMono-Regular",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  text: {
    fontFamily: "FiraMono-Regular",
  },

  step: {
    fontFamily: "FiraMono-Regular",
    "&$completed": {
      color: "#778548",
    },
    "&$active": {
      color: "#714885",
    },
  },

  active: {}, //needed so that the &$active tag works
  completed: {}, //needed so that the &$completed tag works
}));
