import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  footer: {
    backgroundColor: "#C4D19B",
    padding: "25px 0",
    marginTop: "64px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
      fontSize: "15px",
    },
  },

  box: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "40px",
    },
  },

  title: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "26px",
    paddingBottom: "10px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    color: "#714885",
    width: "90%",
    margin: "0 auto 20px auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  link: {
    marginBottom: "10px",
  },

  email: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      textDecoration: "black 1px underline",
    },
  },

  socialIcon: {
    marginRight: "10px",
    verticalAlign: "-7px",
    color: "#595959",
  },

  copy: {
    color: "#595959",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },

  pipe: {
    color: "#595959",
  },
}));
