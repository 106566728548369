import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Commerce from "@chec/commerce.js";
import useStyles from "./styles";

const Detail = ({ onAddToCart }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const history = useHistory();
  const commerce = new Commerce(process.env.REACT_APP_CHEC_PUBLIC_KEY, true);

  const fetchProducts = async () => {
    const data = await commerce.products.retrieve(id);
    setItem(data);
  };

  const redirect = () => {
    setTimeout(() => {
      history.push("/cart");
    }, 2000);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  if (item === null) {
    return (
      <div className={classes.loadingDiv}>
        <CircularProgress size={80} className={classes.loading} />
      </div>
    );
  } else {
    return (
      <>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Typography className={classes.name} gutterBottom>
              {item.name}
            </Typography>

            <Typography className={classes.price}>
              {item.price.formatted_with_symbol}
            </Typography>
          </div>
        </div>

        <div className={classes.cardContent}>
          <Paper className={classes.paper} direction="column">
            <Grid className={classes.imgGrid} item sm={12}>
              <img className={classes.img} src={item.image.url} alt="Bird" />
            </Grid>

            <Grid className={classes.gridBottom} item sm={12}>
              <div className={classes.spacer}></div>

              <Typography
                dangerouslySetInnerHTML={{ __html: item.description }}
                className={classes.description}
              />

              <div className={classes.buttonHolder}>
                <Button
                  className={classes.backButton}
                  size="large"
                  component={Link}
                  to="/"
                  variant="outlined"
                  type="button"
                >
                  Home
                </Button>
                <Button
                  className={classes.cartButton}
                  size="large"
                  color="primary"
                  startIcon={<AddShoppingCart />}
                  variant="contained"
                  onClick={() => [redirect(), onAddToCart(item.id, 1)]}
                >
                  Add to Cart
                </Button>
              </div>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
};

export default Detail;
