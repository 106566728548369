import React from "react";
import { Button } from "@material-ui/core";
import postlist from "../../../posts.json";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import useStyles from "./styles";

const Postlist = () => {
  const classes = useStyles();
  const excerptList = postlist.map((post) => {
    return post.content.split(" ").slice(0, 60).join(" ") + " ...";
  });

  if (postlist.length === 0) {
    return (
      <div className={classes.postList}>
        <h1 className={classes.title}>The Dapper Blog</h1>
        <h1 className={classes.error}>No Posts Found!</h1>
        <h1 className={classes.error}>
          Visit{" "}
          <a className={classes.link} href="/admin">
            /Admin
          </a>{" "}
          to start your blog
        </h1>
      </div>
    );
  } else
    return (
      <div className={classes.postList}>
        <h1 className={classes.title}>The Dapper Blog</h1>
        {postlist.length &&
          postlist.map((post, i) => {
            return (
              <div key={i} className={classes.postCard}>
                <h2 className={classes.heading}>
                  <Link className={classes.link} to={`/post/${post.id}`}>
                    {post.title}
                  </Link>
                </h2>
                <div className={classes.center}>
                  <small className={classes.date}>
                    Published on {post.date} by {post.author}
                  </small>
                </div>
                <hr className={classes.hr} />
                <div className={classes.root}>
                  <Markdown
                    children={excerptList[i]}
                    className={classes.markdown}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
                <div className={classes.readmore}>
                  <Button
                    className={classes.readMoreButton}
                    component={Link}
                    to={`/post/${post.id}`}
                    size="small"
                    type="button"
                    variant="contained"
                  >
                    Read More...
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
    );
};

export default Postlist;
