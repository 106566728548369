import React, { Fragment } from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CartItem from "./CartItem/CartItem";
import useStyles from "./styles";

const Cart = ({
  cart,
  handleUpdateCartQty,
  handleRemoveFromCart,
  handleEmptyCart,
}) => {
  const classes = useStyles();

  const EmptyCart = () => (
    <div className={classes.container}>
      <Typography className={classes.noItemsText}>
        There are no items in your shopping cart
      </Typography>
      <Button
        className={classes.shopButton}
        component={Link}
        to="/"
        size="large"
        type="button"
        variant="contained"
        color="primary"
      >
        Shop Now
      </Button>
    </div>
  );

  const FilledCart = () => (
    <Fragment>
      <Grid container spacing={4} style={{ marginTop: "40px" }}>
        {cart.line_items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <CartItem
              item={item}
              onUpdateCartQty={handleUpdateCartQty}
              onRemoveFromCart={handleRemoveFromCart}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.cardDetails}>
        <Typography className={classes.subTotal}>
          Subtotal = {cart.subtotal.formatted_with_symbol}
        </Typography>
        <div className={classes.buttonHolder}>
          <Button
            className={classes.emptyButton}
            size="large"
            variant="outlined"
            type="button"
            // onClick={handleEmptyCart}
            component={Link}
            to="/"
          >
            Continue Shopping
          </Button>
          <Button
            component={Link}
            to="/checkout"
            className={classes.checkoutButton}
            size="large"
            type="button"
            variant="contained"
            color="primary"
          >
            Checkout
          </Button>
        </div>
      </div>
    </Fragment>
  );

  if (!cart.line_items)
    return (
      <div className={classes.loadingDiv}>
        <CircularProgress size={80} className={classes.loading} />
      </div>
    );

  return (
    <Container>
      <div className={classes.toolbar} />
      <Typography className={classes.title} gutterBottom>
        Your Shopping Cart
      </Typography>
      {!cart.line_items.length ? <EmptyCart /> : <FilledCart />}
    </Container>
  );
};

export default Cart;
