import React from "react";
import { Fragment } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ShoppingCart, MenuBook } from "@material-ui/icons";
import logo from "../../assets/DapperOnTransparent.svg";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const Navbar = ({ totalItems }) => {
  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  return (
    <Fragment>
      <AppBar position="sticky" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
            color="inherit"
          >
            <img src={logo} alt="Dapper Cat" className={classes.image} />
            The Dapper Cat
          </Typography>
          <div className={classes.grow} />
          <div>
            {isSmallOrLess ? (
              <Button
                className={classes.blogButton}
                size="small"
                component={Link}
                to="/blog"
                variant="outlined"
                type="button"
              >
                Visit the Blog
              </Button>
            ) : (
              <IconButton
                component={Link}
                to="/blog"
                aria-label="Blog"
                color="inherit"
              >
                <MenuBook />
              </IconButton>
            )}
          </div>

          <div>
            <IconButton
              component={Link}
              to="/cart"
              aria-label="show cart items"
              color="inherit"
            >
              <Badge
                badgeContent={totalItems}
                classes={{ badge: classes.customBadge }}
              >
                <ShoppingCart />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default Navbar;
