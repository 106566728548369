import React from "react";
import { Postlist } from "..";

const Blog = () => {
  return (
    <div>
      <Postlist />
    </div>
  );
};

export default Blog;
