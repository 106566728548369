import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& p": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    "& img": {
      width: "50%",
      margin: "20px 0 20px 0",
      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
  },

  postList: {
    textAlign: "center",
    maxWidth: "900px",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
  },

  postCard: {
    textAlign: "left",
    marginBottom: "60px",
    padding: "40px",
    backgroundColor: "#C4D19B",
    borderRadius: "4px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
      padding: "30px 20px",
    },
  },

  title: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "100px",
    color: "#714885",
    marginTop: "45px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "60px",
      marginTop: "30px",
      marginBottom: "15px",
      lineHeight: "1.15",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "80px",
      marginTop: "40px",
      marginBottom: "40px",
    },
  },

  error: {
    fontFamily: "FiraMono-Regular",
    fontSize: "27px",
  },

  heading: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "60px",
    color: "#714885",
    margin: "0px 0px 15px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      textAlign: "center",
    },
  },

  link: {
    textDecoration: "none",
    color: "#714885",
    "&:hover": {
      textDecoration: "#714885 2px underline",
      textUnderlineOffset: "5px",
    },
  },

  date: {
    color: "#595959",
    fontFamily: "JosefinSans-Regular",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  center: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  hr: {
    margin: "30px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      margin: "24px",
    },
  },

  markdown: {
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    marginBottom: "20px",
    lineHeight: "1.4",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },

  readmore: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "left",
    },
  },

  readMoreButton: {
    backgroundColor: "#714885",
    color: "#fff",
    padding: "10px 15px 8px 15px",
    fontFamily: "JosefinSans-Regular",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "12px",
    },
    "&:hover": {
      backgroundColor: "#915CAB",
    },
  },
}));
