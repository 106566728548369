import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  subsectionHeader: {
    fontFamily: "FiraMono-Regular",
    fontSize: "30px",
    color: "#595959",
    textAlign: "center",
  },

  spacer: {
    marginTop: "30px",
  },

  text: {
    color: "black",
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    marginBottom: "10px",
  },

  select: {
    fontFamily: "FiraMono-Regular",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    "&:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "&:after": {
      borderBottomColor: "#714885",
    },
  },

  icon: {
    fill: "#915CAB",
  },

  menuItemText: {
    fontFamily: "FiraMono-Regular",
  },

  buttonHolder: {
    marginTop: "30px",
  },

  backButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down(400)]: {
      fontSize: "12px",
      padding: "10px 10px 8px 10px",
    },
  },

  nextButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down(400)]: {
      fontSize: "12px",
      padding: "10px 10px 8px 10px",
    },
  },
}));
