import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, Grid } from "@material-ui/core";
import useStyles from "./styles";

const FormInput = ({ name, label }) => {
  const classes = useStyles();
  const { control } = useFormContext();
  return (
    <Grid item xs={12} sm={6}>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            classes={classes}
            fullWidth
            label={label}
            required
          />
        )}
        defaultValue=""
        control={control}
        name={name}
      />
    </Grid>
  );
};

export default FormInput;
