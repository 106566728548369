import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    display: "flex",
    margin: "32px 0 0 0",
    justifyContent: "center",
  },

  headerContent: {
    display: "flex",
    width: "90%",
    maxWidth: "900px",
    justifyContent: "space-between",
    padding: "0 20px 0 20px",
  },

  name: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "40px",
    color: "#714885",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  price: {
    fontFamily: "JosefinSans-Regular",
    fontSize: "40px",
    color: "#714885",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  cardContent: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginBottom: "30px",
    },
  },

  paper: {
    maxWidth: "900px",
    margin: "0 auto",
    display: "block",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
  },

  imgGrid: {
    position: "relative",
    // paddingTop: "56.25%", // 16:9
    paddingTop: "75.00%", // 4:3
  },

  img: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "4px 4px 0 0",
  },

  gridBottom: {
    padding: "25px",
    backgroundColor: "#C4D19B",
    borderRadius: "0 0 4px 4px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },

  spacer: {
    display: "flex",
    justifyContent: "space-between",
  },

  description: {
    fontFamily: "FiraMono-Regular",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },

  buttonHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px",
  },

  backButton: {
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
    [theme.breakpoints.down(400)]: {
      width: "100px",
      height: "40px",
    },
  },

  cartButton: {
    backgroundColor: "#714885",
    "&:hover": {
      backgroundColor: "#915CAB",
    },
    fontFamily: "JosefinSans-Regular",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      height: "42px",
      fontSize: "12px",
      padding: "13px 10px 10px 10px",
    },
    [theme.breakpoints.down(400)]: {
      width: "134px",
      height: "40px",
    },
  },

  loadingDiv: {
    width: "100%",
    height: "100%",
  },

  loading: {
    position: "fixed",
    left: "50%",
    top: "35%",
    color: "#714885",
    [theme.breakpoints.down("sm")]: {
      left: "42%",
      top: "22%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      left: "46%",
      top: "30%",
    },
  },
}));
